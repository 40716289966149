import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {catchError, EMPTY, Observable, throwError, timeout} from 'rxjs';
import {DialogErrorService} from "../../shared/components/dialog-error/dialog-error.service";
import * as Sentry from "@sentry/angular";
import {DialogErrorData} from "../../shared/components/dialog-error/models/dialog-error";
import {UiUtils} from "../../shared/utils/ui.utils";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  private readonly WHITELISTED_URLS = [
    /^https:\/\/(rool-(?:reference|wear)-(?:dev|val|prod))\.s3\.eu-west-1\.amazonaws\.com\/analyses\/.*$/,
    /^https:\/\/carool-configuration-(?:dev|val|prod).s3.eu-west-1.amazonaws.com\/.*$/,
    /^https:\/\/(?:dev\.rolling\.|val\.rolling\.|)ca-rool\.com(?::\d+)?\/api\/reference\/vehicles$/,
    /^https:\/\/(?:dev\.rolling\.|val\.rolling\.|)ca-rool\.com(?::\d+)?\/api\/reference\/analyses\/.*\/contacts$/,
    /^https:\/\/(?:dev\.rolling\.|val\.rolling\.|)ca-rool\.com(?::\d+)?\/api\/external\/v1\/countries\/.*\/settings$/,
    /^assets\/.*$/
  ];
  private readonly TIMEOUT_URLS = [
    {
      url: /^https:\/\/(?:dev\.rolling\.|val\.rolling\.|)ca-rool\.com\/api\/.*$/,
      timeout: 5000
    }
  ]
  private readonly interceptedHttpCodes = [0, 400, 401, 403, 409, 500];

  constructor(private dialogService: DialogErrorService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const to = this.TIMEOUT_URLS.find((value) => request.url.match(value.url))?.timeout;
    let request$ = next.handle(request);

    // If the request has timeout management then do it.
    if (to) {
      request$ = request$.pipe(timeout(to),
        catchError((response: Error) => {
          if (response.name === 'TimeoutError') {
            this.dialogService.show(UiUtils.getNoNetworkDialog());
            Sentry.captureMessage('Timeout no network', {extra: {response}});
            return EMPTY;
          } else {
            return throwError(() => response as HttpErrorResponse);
          }
        }))
    }

    return request$.pipe(catchError((response: HttpErrorResponse) => {
      const isWhitelistedUrl = this.WHITELISTED_URLS.some((regex) => request.url?.match(regex))
      if (!isWhitelistedUrl && this.interceptedHttpCodes.includes(response.status) && response.url?.startsWith('http')) {
        let data = new DialogErrorData();
        switch (response.status) {
          case 0:
            data = UiUtils.getNoNetworkDialog();
            Sentry.captureException(new Error('Http Network Error'), {extra: {response}});
            break;
          case 400:
          case 401:
          case 403:
          case 409:
            Sentry.captureException(new Error(`Http ${response.status} Error`), {extra: {response}});
            return throwError(() => response);
          case 500:
            data.title = 'error.unknown.title';
            Sentry.captureException(new Error('Http Backend Error'), {extra: {response}});
            break;
        }
        if (data.title) this.dialogService.show(data);
        return EMPTY;
      }
      return throwError(() => response);
    }));
  }
}
