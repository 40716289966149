{
  "name": "carool-web-app",
  "version": "4.52.1",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test carool-web-app",
    "test-ci": "ng test carool-web-app --watch=false --browsers=Headless",
    "test-cypress": "npx cypress run --e2e --reporter junit --browser chrome",
    "test-spec": "node scripts/run-spec.js",
    "test-cypress-ci": "WAIT_ON_TIMEOUT=1200000 start-server-and-test start http://localhost:4200 test-cypress"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.5",
    "@angular/cdk": "^18.2.4",
    "@angular/common": "^18.2.5",
    "@angular/compiler": "^18.2.5",
    "@angular/core": "^18.2.5",
    "@angular/forms": "^18.2.5",
    "@angular/material": "^18.2.4",
    "@angular/material-moment-adapter": "^18.2.4",
    "@angular/platform-browser": "^18.2.5",
    "@angular/platform-browser-dynamic": "^18.2.5",
    "@angular/router": "^18.2.5",
    "@carool1/ngx-carool-ds": "^2.3.1",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@sentry/angular": "^8.27.0",
    "@sentry/cli": "^2.31.0",
    "@types/google.maps": "^3.55.7",
    "angularx-qrcode": "^18.0.1",
    "aws-amplify": "^6.0.28",
    "deepmerge-ts": "^5.1.0",
    "detect-browser": "^5.3.0",
    "google-libphonenumber": "^3.2.34",
    "jwt-decode": "^4.0.0",
    "lodash": "^4.17.21",
    "moment": "^2.30.1",
    "ngx-matomo-client": "^6.1.3",
    "ngx-translate-multi-http-loader": "^16.0.2",
    "rxjs": "~7.8.1",
    "tslib": "^2.6.2",
    "web-animations-js": "^2.3.2",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.5",
    "@angular/cli": "^18.2.5",
    "@angular/compiler-cli": "^18.2.5",
    "@types/google-libphonenumber": "^7.4.30",
    "@types/jasmine": "~4.3.1",
    "@types/lodash": "^4.17.0",
    "@types/node": "^18.16.12",
    "@types/qrcode": "^1.5.5",
    "autoprefixer": "^10.4.2",
    "cypress": "^13.7.3",
    "jasmine-core": "~5.0.1",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.6",
    "start-server-and-test": "^2.0.0",
    "tailwindcss": "^3.0.23",
    "typescript": "~5.4.5"
  }
}
